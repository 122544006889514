/**
 * Type: ページ
 * What: 団体予約フォームページ
 */
import React, { useEffect, useState } from 'react';
import { navigate, graphql, Link } from 'gatsby';
import axios from 'axios';
import { scroller } from 'react-scroll';
import { useForm } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
import moment from 'moment/moment';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import '../../sass/components/_form.scss';
import { Button } from '../../components/Btn';
import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ja', ja);

const pageTitle = '団体予約フォーム';
const pageSlug = 'inquiry';

export const query = graphql`
  {
    site(siteMetadata: {}) {
      siteMetadata {
        endPoint
        endPointApiGroupForm
      }
    }
  }
`;

// 予約受付除外日 (月は0から始まるので注意)
const calendarExcludeDates = [
  new Date(2025, 0, 1),
  new Date(2025, 0, 2),
  new Date(2025, 0, 3),
  new Date(2025, 0, 4),
  new Date(2025, 0, 5),
  new Date(2025, 4, 3),
  new Date(2025, 4, 4),
  new Date(2025, 4, 5),
  new Date(2025, 4, 6),
  new Date(2025, 7, 9),
  new Date(2025, 7, 10),
  new Date(2025, 7, 11),
  new Date(2025, 7, 12),
  new Date(2025, 7, 13),
  new Date(2025, 7, 14),
  new Date(2025, 7, 15),
  new Date(2025, 7, 16),
  new Date(2025, 7, 17),
  new Date(2025, 11, 27),
  new Date(2025, 11, 28),
  new Date(2025, 11, 29),
  new Date(2025, 11, 30),
  new Date(2025, 11, 31),
  new Date(2026, 0, 1),
  new Date(2026, 0, 2),
  new Date(2026, 0, 3),
  new Date(2026, 0, 4),
];

const calendarExcludeDates89 = [
  new Date(2025, 0, 1),
  new Date(2025, 0, 2),
  new Date(2025, 0, 3),
  new Date(2025, 0, 4),
  new Date(2025, 0, 5),
  new Date(2025, 4, 3),
  new Date(2025, 4, 4),
  new Date(2025, 4, 5),
  new Date(2025, 4, 6),
  new Date(2025, 7, 9),
  new Date(2025, 7, 10),
  new Date(2025, 7, 11),
  new Date(2025, 7, 12),
  new Date(2025, 7, 13),
  new Date(2025, 7, 14),
  new Date(2025, 7, 15),
  new Date(2025, 7, 16),
  new Date(2025, 7, 17),
  new Date(2025, 11, 27),
  new Date(2025, 11, 28),
  new Date(2025, 11, 29),
  new Date(2025, 11, 30),
  new Date(2025, 11, 31),
  new Date(2026, 0, 1),
  new Date(2026, 0, 2),
  new Date(2026, 0, 3),
  new Date(2026, 0, 4),
];

// Data Props Template
const useFormPage = ({ data }) => {
  const dt = new Date();
  const dd = moment(new Date()).add(18, 'M').toDate();
  const { register, handleSubmit, errors, reset, getValues } = useForm();
  const [Token, setToken] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const hideConfirmation = () => setIsConfirmationVisible(false);
  const onSubmitData = () => setIsConfirmationVisible(true);
  const [Formdata, setFormdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [courseItems1, setCourseItems1] = useState(''); // VWorld1h
  const [courseItems2, setCourseItems2] = useState(''); // チームラボ
  const [courseItems3, setCourseItems3] = useState(''); // OBM
  const [courseItems4, setCourseItems4] = useState(''); // すべZO
  const [courseItems5, setCourseItems5] = useState(''); // OBM89
  const [courseItems6, setCourseItems6] = useState(''); // VWorld2h
  const [handicappedItem, setHandicappedItem] = useState('なし');
  const [eatUse, setEatUse] = useState('予定なし');
  const [selectedDate, setSelectedDate] = useState(
    dt.setDate(dt.getDate() + 14)
  );
  const [bus, setBus] = useState(true);
  const [park, setPark] = useState(0);
  const [isModal, setIsModal] = useState(false);

  const APIURL = data.site.siteMetadata.endPointApiGroupForm;
  // const APIURL = data.site.siteMetadata.endPoint;
  const formValidate = `${APIURL}/group_ticket_validate`;
  const formSubmit = `${APIURL}/group_ticket_submit`;
  const formToken = `${APIURL}/form_token`;
  // チェック状態を取得してStateへ
  const handleChecked1 = (e) => {
    if (e.target.checked) {
      setCourseItems1(e.target.name);
    } else {
      setCourseItems1('');
    }
  };
  const handleChecked2 = (e) => {
    if (e.target.checked) {
      setCourseItems2(e.target.name);
    } else {
      setCourseItems2('');
    }
  };
  const handleChecked3 = (e) => {
    if (e.target.checked) {
      setCourseItems3(e.target.name);
    } else {
      setCourseItems3('');
    }
  };
  const handleChecked4 = (e) => {
    if (e.target.checked) {
      setCourseItems4(e.target.name);
    } else {
      setCourseItems4('');
    }
  };
  const handleChecked5 = (e) => {
    if (e.target.checked) {
      setCourseItems5(e.target.name);
    } else {
      setCourseItems5('');
    }
  };
  const handleChecked6 = (e) => {
    if (e.target.checked) {
      setCourseItems6(e.target.name);
    } else {
      setCourseItems6('');
    }
  };
  const handleHandicapped = (e) => {
    if (e.target.checked) {
      setHandicappedItem(e.target.value);
    } else {
      setHandicappedItem('');
    }
  };
  const handleEatUse = (e) => {
    if (e.target.checked) {
      setEatUse(e.target.value);
    } else {
      setEatUse('');
    }
  };

  // 日程選択
  const handleDate = (e) => {
    setSelectedDate(e);
  };

  // バスチェック
  const handleBus = (e) => {
    const val = e.target.value;
    if (val === '利用なし') {
      setBus(true);
    } else {
      setBus(false);
      setPark(1);
    }
  };

  // エラーメッセージにスクロール
  const scrollToTarget = () => {
    scroller.scrollTo('errorMsg', {
      duration: 800,
      delay: 0,
      offset: -70,
      smooth: 'easeOutQuint',
    });
  };

  // 初回アクセス時にトークン取得
  const tokenFetch = () => {
    axios
      .post(formToken, { timeout: 20000 })
      .then((res) => {
        if (res.data.errors.length === 0) {
          // エラーメッセージがあるかどうか 配列が空なら成功
          setToken(res.data.token);
        } else {
          setErrorMessage(res.data.errors);
        }
      })
      .catch((err) => {
        // サーバーエラー
        setErrorMessage(['サーバーエラーが発生しました。']);
      });
  };

  // バリデーション
  const validationPost = () => {
    const items = [
      courseItems3,
      courseItems5,
      courseItems2,
      courseItems1,
      courseItems6,
      courseItems4,
    ].filter(Boolean);
    setSubmit(true);
    const values = getValues();
    if (items.length) {
      values.course = items;
    } else {
      values.course = [''];
    }
    values.date = moment(selectedDate).format('Y-M-D');
    // console.log(values);
    values.token = Token;
    const countPeople =
      Number(values.no_of_23) +
      Number(values.no_of_16_22) +
      Number(values.no_of_13_15) +
      Number(values.no_of_10_12) +
      Number(values.no_of_6_9) +
      Number(values.no_of_4_6) +
      Number(values.no_of_3);
    const customErrorBody = [
      '参加人数 : 合計11名様以上よりお申し込み出来ます。',
    ];

    axios
      .post(formValidate, values, {
        timeout: 20000,
      })
      .then((res) => {
        if (res.data.errors.length === 0 && countPeople > 10) {
          // エラーメッセージがあるかどうか 配列が空なら成功
          // console.log('バリデーションOK');
          confirmTable();
          setErrorMessage(false);
          setTimeout(() => {
            setOpen(true);
            setSubmit(false);
          }, 300);
        } else {
          // console.log('バリデーションNG');
          hideConfirmation();
          setSubmit(false);
          setErrorMessage(
            countPeople < 11
              ? [customErrorBody, ...res.data.errors]
              : res.data.errors
          );
          scrollToTarget();
        }
      })
      .catch((err) => {
        // サーバーエラー
        setSubmit(false);
        setErrorMessage(['サーバーエラーが発生しました。']);
        // setErrorMessage(err.response.data.message);
      });
  };

  // フォーム送信
  const dataPost = () => {
    const items = [
      courseItems3,
      courseItems5,
      courseItems2,
      courseItems1,
      courseItems6,
      courseItems4,
    ].filter(Boolean);
    const values = getValues();
    if (items.length) {
      values.course = items;
    } else {
      values.course = [''];
    }
    values.date = moment(selectedDate).format('Y-M-D');
    // console.log(values);
    values.token = Token;
    setSubmit(true);

    axios
      .post(formSubmit, values, {
        timeout: 20000,
      })
      .then((res) => {
        if (res.data.errors.length === 0) {
          // エラーメッセージがあるかどうか 配列が空なら成功
          setOpen(false);
          setSubmit(false);
          setTimeout(() => {
            navigate(
              // 完了ページへ
              '/inquiry/completed_group/',
              { replace: false }
            );
          }, 600);
        } else {
          hideConfirmation();
          scrollToTarget();
          setSubmit(false);
          setErrorMessage(res.data.errors);
        }
      })
      .catch((err) => {
        hideConfirmation();
        scrollToTarget();
        setSubmit(false);
        setErrorMessage(['サーバーエラーが発生しました。']);
      });
  };

  // モーダル非表示
  const modalClose = () => {
    setOpen(false);
    setTimeout(() => {
      hideConfirmation();
    }, 600);
  };

  // 確認画面のテーブルを自動で組み立て
  const confirmTable = () => {
    const labels = document.querySelectorAll('form .label');
    const control = document.querySelectorAll('form .control');
    const temp = [];

    for (let i = 0; labels.length > i; i++) {
      const label = labels[i];
      const labelRow = label.firstChild.data;
      const text = labelRow.replace('wovn-src:', '');

      let { value } = control[i].firstChild;
      value =
        value ||
        (control[i].firstChild.firstChild &&
          control[i].firstChild.firstChild.value) ||
        '-';

      temp.push(
        <tr key={i}>
          <th wovn-ignore="true">{text}</th>
          <td className={`value${i}`} wovn-ignore="true">
            {value}
          </td>
        </tr>
      );
    }

    setFormdata(temp);
  };

  useEffect(() => {
    tokenFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!bus && park === '0') {
      window.alert('バス駐車場ご利用の方は、バス台数を1以上で選択ください');
      setPark(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [park]);

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="section">
        <div className="container">
          <h3 className="title is-3">{pageTitle}</h3>
          <p className="notification sub-desc mb-2">
            E・ZO FUKUOKAの団体申込みフォームです。
            <br />
            11名様以上でお申し込みください。（20名様より団体割引適用）
          </p>
          <p className="notification is-info is-light sub-desc mb-2">
            <b>参加希望時間は下記所要時間を参考にご設定ください</b>
            <br />
            王貞治ベースボールミュージアム 1時間から1時間30分
            <br />
            チームラボフォレスト福岡 -SBI証券　1時間
            <br />
            V-World AREA クレディ・アグリコル　2時間または1時間
            <br />
            絶景三兄弟「すべZO」SMBC日興証券　1時間当たり40名
          </p>
          <p className="notification is-danger is-light sub-desc" id="errorMsg">
            迷惑メール対策等をされている場合は「@softbankhawks.co.jp」のドメインを受信できるように設定のうえ、ご予約ください。
          </p>
          <div className="btn-wrap mb-5">
            <button
              type="button"
              className="btn-regular btn-regular"
              onClick={() => setIsModal(true)}
            >
              <span>各アトラクションの料金</span>
              <svg viewBox="0 0 490.8 490.8" xmlns="http://www.w3.org/2000/svg">
                <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
              </svg>
            </button>
          </div>
          <div className="inner-slim u-mt-50">
            {errorMessage && (
              <div className="notification is-danger">
                {Object.keys(errorMessage).map((key) => (
                  <p className="error-list" key={key}>
                    {errorMessage[key]}
                  </p>
                ))}
              </div>
            )}
            {isConfirmationVisible && Token && (
              <>
                <div className={`confirmation ${open ? 'open' : ''}`}>
                  <div className="confirmation-inner">
                    <h3 className="title is-3">内容を確認</h3>
                    <div className="contactBox">
                      <table className="table is-striped is-bordered is-fullwidth">
                        <tbody>{Formdata}</tbody>
                      </table>
                      <p className="has-text-align-center mb20">
                        上記の内容でよろしければ、下記個人情報の取扱いについてご同意の上、送信ボタンを押して下さい。
                        <br />
                        個人情報の取り扱いは
                        <a
                          href="https://www.softbankhawks.co.jp/company/privacy_asobian.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          こちら
                        </a>
                        からご確認いただけます。
                      </p>
                      <div className="btn-group">
                        <div className="buttons control">
                          <button
                            type="button"
                            onClick={modalClose} // クリックでstateをクリアし、入力内容確認画面コンポーネントを非表示にする
                            className="btn-def reverse"
                          >
                            <span>閉じる</span>
                          </button>
                          <button
                            type="submit"
                            className={`btn-def submit ${submit && 'spin'} `}
                            onClick={dataPost}
                          >
                            <span>内容を送信</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Confirmation// 入力内容確認画面コンポーネント
                values={getValues()}
                hideConfirmation={hideConfirmation}
                isConfirmationVisible={isConfirmationVisible}
                token={Token}
              /> */}
              </>
            )}

            <form onSubmit={handleSubmit(onSubmitData)} name="contactForm">
              <div className="field">
                <label className="label" htmlFor="Course">
                  ご利用希望アトラクション{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div className="control">
                  <label className="checkbox is-flex">
                    <input
                      type="checkbox"
                      name="王貞治ベースボールミュージアム"
                      wovn-ignore="true"
                      value={`
                      ${courseItems3 && courseItems3}\n
                      ${courseItems5 && courseItems5}\n
                      ${courseItems2 && courseItems2}\n
                      ${courseItems1 && courseItems1}\n
                      ${courseItems6 && courseItems6}\n
                      ${courseItems4 && courseItems4}\n
                      `}
                      onChange={(e) => handleChecked3(e)}
                    />
                    <span>
                      王貞治ベースボールミュージアム
                      <br />
                      ／OH Sadaharu Baseball Museum
                    </span>
                  </label>
                  <label className="checkbox is-flex">
                    <input
                      type="checkbox"
                      name="王貞治ベースボールミュージアム「89パーク」"
                      wovn-ignore="true"
                      onChange={(e) => handleChecked5(e)}
                    />
                    <span>
                      王貞治ベースボールミュージアム「89パーク」
                      <br />
                      HAWKS Baseball Experience
                    </span>
                  </label>
                  <label className="checkbox is-flex">
                    <input
                      type="checkbox"
                      name="チームラボフォレスト"
                      wovn-ignore="true"
                      onChange={(e) => handleChecked2(e)}
                    />
                    <span>
                      チームラボフォレスト 福岡 – SBI証券
                      <br />
                      teamLab Forest Fukuoka - SBI SECURITIES Co., Ltd.
                    </span>
                  </label>
                  <label className="checkbox is-flex">
                    <input
                      type="checkbox"
                      name="V-World AREA（1時間）"
                      wovn-ignore="true"
                      onChange={(e) => handleChecked1(e)}
                    />
                    <span>
                      V-World AREA クレディ・アグリコル（1時間）
                      <br />
                      Virtual Experience AREA Crédit Agricole（1 hour）
                    </span>
                  </label>
                  <label className="checkbox is-flex">
                    <input
                      type="checkbox"
                      name="V-World AREA（2時間）"
                      wovn-ignore="true"
                      onChange={(e) => handleChecked6(e)}
                    />
                    <span>
                      V-World AREA クレディ・アグリコル（2時間）
                      <br />
                      Virtual Experience AREA Crédit Agricole（2 hour）
                    </span>
                  </label>
                  <label className="checkbox is-flex">
                    <input
                      type="checkbox"
                      name="すべZO"
                      wovn-ignore="true"
                      onChange={(e) => handleChecked4(e)}
                    />
                    <span>
                      すべZO SMBC日興証券
                      <br />
                      ZEKKEI Slider SUBE-ZO　SMBC Nikko Securities Inc.
                    </span>
                  </label>
                </div>
                {errors.course && (
                  <p className="help is-alert">{errors.course.message}</p>
                )}
              </div>
              <p className="notification has-text-align-center mb-4">
                各アトラクション毎の注意事項は事前にご確認ください
              </p>
              <hr />
              <div className="field">
                <label className="label" htmlFor="Date">
                  ご利用希望日{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right date-wrap"
                  wovn-ignore="true"
                >
                  <input
                    type="hidden"
                    value={moment(selectedDate).format('Y-M-D')}
                  />
                  <DatePicker
                    className="input"
                    dateFormat="yyyy/MM/dd"
                    excludeDates={
                      courseItems5 !== ''
                        ? calendarExcludeDates89
                        : calendarExcludeDates
                    }
                    selected={selectedDate}
                    minDate={dt}
                    maxDate={dd}
                    onChange={(e) => handleDate(e)}
                    locale="ja"
                    name="date"
                    id="Date"
                    showDisabledMonthNavigation
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-calendar-check" />
                  </span>
                </div>
                <p className="help">
                  ※ご予約可能日は本日より2週間後以降となります。
                  <br />
                  ※クーポン利用のないお客様は3週間先以降からの選択をお願いいたします。
                  <br />
                  ※団体割引適用外の特定日がございます。特定日は選択いただけません。ご利用希望の方はお問い合わせをお願いいたします。
                </p>
                {errors.date && (
                  <p className="help is-alert">{errors.date.message}</p>
                )}
              </div>
              <div className={`field${courseItems3 !== '' ? '' : ' hide'}`}>
                <label className="label" htmlFor="Time">
                  王貞治ベースボールミュージアムの参加希望時間{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <div className="select">
                    <select
                      name="time"
                      id="Time"
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option
                        value={`${courseItems3 !== '' ? '' : '希望なし'}`}
                        selected={`${courseItems3 !== '' ? '' : 'selected'}`}
                      >
                        参加の方は選択してください
                      </option>
                      <option value="10:00~11:00（土日祝のみ）">
                        10:00~11:00（土日祝のみ）
                      </option>
                      <option value="11:00~12:00">11:00~12:00</option>
                      <option value="12:00~13:00">12:00~13:00</option>
                      <option value="13:00~14:00">13:00~14:00</option>
                      <option value="14:00~15:00">14:00~15:00</option>
                      <option value="15:00~16:00">15:00~16:00</option>
                      <option value="16:00~17:00">16:00~17:00</option>
                      <option value="17:00~18:00">17:00~18:00</option>
                      <option value="18:00~19:00">18:00~19:00</option>
                      <option value="19:00~20:00">19:00~20:00</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    <i className="fa fa-clock" />
                  </span>
                </div>
                <p className="help">
                  ※ご予約可能時間は11:00～19:00の間となります。
                  <br />
                  ※上記以外の時間をご希望の場合は備考欄にご記入ください。
                </p>
                {errors.time && (
                  <p className="help is-alert">{errors.time.message}</p>
                )}
              </div>
              <div className={`field${courseItems5 !== '' ? '' : ' hide'}`}>
                <label className="label" htmlFor="Time5">
                  王貞治ベースボールミュージアム「89パーク」の参加希望時間{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <div className="select">
                    <select
                      name="time5"
                      id="Tim5"
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option
                        value={`${courseItems5 !== '' ? '' : '希望なし'}`}
                        selected={`${courseItems5 !== '' ? '' : 'selected'}`}
                      >
                        参加の方は選択してください
                      </option>
                      <option value="10:00~11:00（土日祝のみ）">
                        10:00~11:00（土日祝のみ）
                      </option>
                      <option value="11:00~12:00">11:00~12:00</option>
                      <option value="12:00~13:00">12:00~13:00</option>
                      <option value="13:00~14:00">13:00~14:00</option>
                      <option value="14:00~15:00">14:00~15:00</option>
                      <option value="15:00~16:00">15:00~16:00</option>
                      <option value="16:00~17:00">16:00~17:00</option>
                      <option value="17:00~18:00">17:00~18:00</option>
                      <option value="18:00~19:00">18:00~19:00</option>
                      <option value="19:00~20:00">19:00~20:00</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    <i className="fa fa-clock" />
                  </span>
                </div>
                <p className="help">
                  ※ご予約可能時間は11:00～19:00の間となります。
                  <br />
                  ※上記以外の時間をご希望の場合は備考欄にご記入ください。
                </p>
                {errors.time5 && (
                  <p className="help is-alert">{errors.time5.message}</p>
                )}
              </div>
              <div className={`field${courseItems2 !== '' ? '' : ' hide'}`}>
                <label className="label" htmlFor="Time2">
                  チームラボフォレストの参加希望時間{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <div className="select">
                    <select
                      name="time2"
                      id="Time2"
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option
                        value={`${courseItems2 !== '' ? '' : '希望なし'}`}
                        selected={`${courseItems2 !== '' ? '' : 'selected'}`}
                      >
                        参加の方は選択してください
                      </option>
                      <option value="10:00~11:00（土日祝のみ）">
                        10:00~11:00（土日祝のみ）
                      </option>
                      <option value="11:00~12:00">11:00~12:00</option>
                      <option value="12:00~13:00">12:00~13:00</option>
                      <option value="13:00~14:00">13:00~14:00</option>
                      <option value="14:00~15:00">14:00~15:00</option>
                      <option value="15:00~16:00">15:00~16:00</option>
                      <option value="16:00~17:00">16:00~17:00</option>
                      <option value="17:00~18:00">17:00~18:00</option>
                      <option value="18:00~19:00">18:00~19:00</option>
                      <option value="19:00~20:00">19:00~20:00</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    <i className="fa fa-clock" />
                  </span>
                </div>
                <p className="help">
                  ※ご予約可能時間は11:00～19:00の間となります。
                  <br />
                  ※上記以外の時間をご希望の場合は備考欄にご記入ください。
                </p>
                {errors.time2 && (
                  <p className="help is-alert">{errors.time2.message}</p>
                )}
              </div>
              <div className={`field${courseItems1 !== '' ? '' : ' hide'}`}>
                <label className="label" htmlFor="Time6">
                  V-World AREA クレディ・アグリコル（1時間）の参加希望時間{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <div className="select">
                    <select
                      name="time6"
                      id="Time6"
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option
                        value={`${courseItems1 !== '' ? '' : '希望なし'}`}
                        selected={`${courseItems1 !== '' ? '' : 'selected'}`}
                      >
                        参加の方は選択してください
                      </option>
                      <option value="10:00~11:00（土日祝のみ）">
                        10:00~11:00（土日祝のみ）
                      </option>
                      <option value="11:00~12:00">11:00~12:00</option>
                      <option value="12:00~13:00">12:00~13:00</option>
                      <option value="13:00~14:00">13:00~14:00</option>
                      <option value="14:00~15:00">14:00~15:00</option>
                      <option value="15:00~16:00">15:00~16:00</option>
                      <option value="16:00~17:00">16:00~17:00</option>
                      <option value="17:00~18:00">17:00~18:00</option>
                      <option value="18:00~19:00">18:00~19:00</option>
                      <option value="19:00~20:00">19:00~20:00</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    <i className="fa fa-clock" />
                  </span>
                </div>
                <p className="help">
                  ※上記以外の時間をご希望の場合は備考欄にご記入ください。
                </p>
                {errors.time6 && (
                  <p className="help is-alert">{errors.time6.message}</p>
                )}
              </div>
              <div className={`field${courseItems6 !== '' ? '' : ' hide'}`}>
                <label className="label" htmlFor="Time3">
                  V-World AREA クレディ・アグリコル（2時間）の参加希望時間{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <div className="select">
                    <select
                      name="time3"
                      id="Time3"
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option
                        value={`${courseItems6 !== '' ? '' : '希望なし'}`}
                        selected={`${courseItems6 !== '' ? '' : 'selected'}`}
                      >
                        参加の方は選択してください
                      </option>
                      <option value="10:00~12:00（土日祝のみ）">
                        10:00~12:00（土日祝のみ）
                      </option>
                      <option value="11:00~13:00">11:00~13:00</option>
                      <option value="12:00~14:00">12:00~14:00</option>
                      <option value="13:00~15:00">13:00~15:00</option>
                      <option value="14:00~16:00">14:00~16:00</option>
                      <option value="15:00~17:00">15:00~17:00</option>
                      <option value="16:00~18:00">16:00~18:00</option>
                      <option value="17:00~19:00">17:00~19:00</option>
                      <option value="18:00~20:00">18:00~20:00</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    <i className="fa fa-clock" />
                  </span>
                </div>
                <p className="help">
                  ※上記以外の時間をご希望の場合は備考欄にご記入ください。
                </p>
                {errors.time3 && (
                  <p className="help is-alert">{errors.time3.message}</p>
                )}
              </div>
              <div className={`field${courseItems4 !== '' ? '' : ' hide'}`}>
                <label className="label" htmlFor="Time4">
                  すべZO SMBC日興証券の参加希望時間{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <div className="select">
                    <select
                      name="time4"
                      id="Time4"
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option
                        value={`${courseItems4 !== '' ? '' : '希望なし'}`}
                        selected={`${courseItems4 !== '' ? '' : 'selected'}`}
                      >
                        参加の方は選択してください
                      </option>
                      <option value="10:00~11:00（土日祝のみ）">
                        10:00~11:00（土日祝のみ）
                      </option>
                      <option value="11:00~12:00">11:00~12:00</option>
                      <option value="12:00~13:00">12:00~13:00</option>
                      <option value="13:00~14:00">13:00~14:00</option>
                      <option value="14:00~15:00">14:00~15:00</option>
                      <option value="15:00~16:00">15:00~16:00</option>
                      <option value="16:00~17:00">16:00~17:00</option>
                      <option value="17:00~18:00">17:00~18:00</option>
                      <option value="18:00~19:00">18:00~19:00</option>
                      <option value="19:00~20:00">19:00~20:00</option>
                    </select>
                  </div>
                  <span className="icon is-small is-left">
                    <i className="fa fa-clock" />
                  </span>
                </div>
                <p className="help">
                  ※ご予約可能時間は11:00～19:00の間となります。
                  <br />
                  ※上記以外の時間をご希望の場合は備考欄にご記入ください。
                </p>
                {errors.time4 && (
                  <p className="help is-alert">{errors.time4.message}</p>
                )}
              </div>
              <hr />
              <h4 className="title is-size-5 mb-2">ご利用人数</h4>
              <p className="list-mark mb-0">
                ※合計11名様以上でお申し込みください
              </p>
              <p className="list-mark mb-5">
                ※現時点でお分かりになる範囲でご記載ください
              </p>
              <div className="field">
                <label className="label" htmlFor="NoOf23">
                  23歳以上{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.no_of_23 ? 'input error' : 'input'}
                    type="number"
                    placeholder="人数を数字で入力ください"
                    name="no_of_23"
                    id="NoOf23"
                    defaultValue="0"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                {errors.no_of_23 && (
                  <p className="help is-alert">{errors.no_of_23.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="NoOf1622">
                  16歳～22歳{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.no_of_16_22 ? 'input error' : 'input'}
                    type="number"
                    placeholder="人数を数字で入力ください"
                    name="no_of_16_22"
                    id="NoOf1622"
                    defaultValue="0"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                {errors.no_of_16_22 && (
                  <p className="help is-alert">{errors.no_of_16_22.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="NoOf1315">
                  13歳～15歳{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.no_of_13_15 ? 'input error' : 'input'}
                    type="number"
                    placeholder="人数を数字で入力ください"
                    name="no_of_13_15"
                    id="NoOf1315"
                    defaultValue="0"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                {errors.no_of_13_15 && (
                  <p className="help is-alert">{errors.no_of_13_15.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="NoOf1012">
                  10歳～12歳{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.no_of_10_12 ? 'input error' : 'input'}
                    type="number"
                    placeholder="人数を数字で入力ください"
                    name="no_of_10_12"
                    id="NoOf1012"
                    defaultValue="0"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                {errors.no_of_10_12 && (
                  <p className="help is-alert">{errors.no_of_10_12.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="NoOf69">
                  6歳（小学生以上）～9歳{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.no_of_6_9 ? 'input error' : 'input'}
                    type="number"
                    placeholder="人数を数字で入力ください"
                    name="no_of_6_9"
                    id="NoOf69"
                    defaultValue="0"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                {errors.no_of_6_9 && (
                  <p className="help is-alert">{errors.no_of_6_9.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="NoOf46">
                  4歳～6歳（未就学児）{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.no_of_4_6 ? 'input error' : 'input'}
                    type="number"
                    placeholder="人数を数字で入力ください"
                    name="no_of_4_6"
                    id="NoOf46"
                    defaultValue="0"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                {errors.no_of_4_6 && (
                  <p className="help is-alert">{errors.no_of_4_6.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="NoOf3">
                  3歳以下 <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.no_of_3 ? 'input error' : 'input'}
                    type="number"
                    placeholder="人数を数字で入力ください"
                    name="no_of_3"
                    id="NoOf3"
                    defaultValue="0"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                {errors.no_of_3 && (
                  <p className="help is-alert">{errors.no_of_3.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="handiCapped">
                  障がい者割引{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input type="hidden" value={handicappedItem} />
                  <label className="radio" htmlFor="handiCappedYes">
                    <input
                      type="radio"
                      name="handicapped"
                      value="あり"
                      id="handiCappedYes"
                      onChange={(e) => handleHandicapped(e)}
                      ref={register({
                        required: '必須項目です',
                      })}
                    />
                    障がい者割引の適用あり／YES
                  </label>
                  <label className="radio" htmlFor="handiCappedNo">
                    <input
                      type="radio"
                      name="handicapped"
                      value="なし"
                      id="handiCappedNo"
                      onChange={(e) => handleHandicapped(e)}
                      ref={register({
                        required: '必須項目です',
                      })}
                    />
                    障がい者割引の適用なし／NO
                  </label>
                </div>
                <p className="help is-danger mt-2">
                  当日各アトラクション入場口で障がい者手帳のご提示が必要です（付添者は割引対象外）
                  <br />
                  ※対象の人数については、備考欄に記載をお願いいたします
                </p>
                {errors.handicapped && (
                  <p className="help is-alert">{errors.handicapped.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="NoOfDriver">
                  添乗員・バスガイド・バス運転手・同行カメラマン（アトラクションへの同行がある場合のみ）{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <p className="mb-5">
                  <small>
                    添乗員・ガイド・運転手・カメラマン：無料
                    <br />
                    ※招待PASSを発行致します（アトラクションの体験不可）
                  </small>
                </p>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.no_of_driver ? 'input error' : 'input'}
                    type="number"
                    placeholder="人数を数字で入力ください"
                    name="no_of_driver"
                    id="NoOfDriver"
                    defaultValue="0"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                {errors.no_of_driver && (
                  <p className="help is-alert">{errors.no_of_driver.message}</p>
                )}
              </div>
              <hr />
              <div className="field">
                <label className="label" htmlFor="ParkingType">
                  バス駐車場利用{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div className="control">
                  <div className="select">
                    <select
                      name="parking_type"
                      id="ParkingType"
                      onChange={(e) => handleBus(e)}
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option value="">---------ご選択ください---------</option>
                      <option value="大型：留置き希望">大型：留置き希望</option>
                      <option value="中型：留置き希望">中型：留置き希望</option>
                      <option value="マイクロ：留置き希望">
                        マイクロ：留置き希望
                      </option>
                      <option value="駐車場内乗降のみ希望">
                        駐車場内乗降のみ希望
                      </option>
                      <option value="利用なし">利用なし</option>
                    </select>
                  </div>
                </div>
                {errors.parking_type && (
                  <p className="help is-alert">{errors.parking_type.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="ParkingSlots">
                  バス台数{' '}
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className="input"
                    type="number"
                    placeholder="台数を数字で入力ください"
                    defaultValue={bus ? 0 : 1}
                    name="parking_slots"
                    min={bus ? 0 : 1}
                    onChange={(e) => setPark(e.target.value)}
                    value={park}
                    id="ParkingSlots"
                    ref={register}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-users" />
                  </span>
                </div>
                <p className="help">
                  留置き、乗降のみともに無料（台数限定）
                  <br />
                  ※留置き（無料）はE・ZOまたはドームツアーご利用の時間帯に限ります。
                  <br />
                  ※留置き（無料）は台数限定のため、満車時は1日1台につき5,000円（税込）の有料駐車場のご案内となります。
                </p>
              </div>
              <div className="field">
                <label className="label" htmlFor="EatUse">
                  飲食利用{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input type="hidden" value={eatUse} />
                  <label className="radio mb-2" htmlFor="EatUseYes">
                    <input
                      type="radio"
                      name="restaurant"
                      value="E・ZO内飲食店利用"
                      id="EatUseYes"
                      onChange={(e) => handleEatUse(e)}
                      ref={register({
                        required: '必須項目です',
                      })}
                    />
                    E・ZO内飲食店利用／Use restaurants in E・ZO
                  </label>
                  <label className="radio mb-2" htmlFor="EatUseBento">
                    <input
                      type="radio"
                      name="restaurant"
                      value="お弁当"
                      id="EatUseBento"
                      onChange={(e) => handleEatUse(e)}
                      ref={register({
                        required: '必須項目です',
                      })}
                    />
                    お弁当／Lunch Box
                  </label>
                  <label className="radio mb-2 ml-0" htmlFor="EatUseBuffet">
                    <input
                      type="radio"
                      name="restaurant"
                      value="ビュッフェ"
                      id="EatUseBuffet"
                      onChange={(e) => handleEatUse(e)}
                      ref={register({
                        required: '必須項目です',
                      })}
                    />
                    ビュッフェ／Buffet
                  </label>
                  <label className="radio mb-2" htmlFor="EatUseNo">
                    <input
                      type="radio"
                      name="restaurant"
                      value="利用しない"
                      id="EatUseNo"
                      onChange={(e) => handleEatUse(e)}
                      ref={register({
                        required: '必須項目です',
                      })}
                    />
                    利用しない／Not using
                  </label>
                  <label className="radio mb-2" htmlFor="EatUseEven">
                    <input
                      type="radio"
                      name="restaurant"
                      value="検討中"
                      id="EatUseEven"
                      onChange={(e) => handleEatUse(e)}
                      ref={register({
                        required: '必須項目です',
                      })}
                    />
                    検討中／Still Considering
                  </label>
                </div>
                <p className="help mt-2">
                  ＜弊社よりご返信の際にお食事をご提案させていただくことがございます＞
                </p>
                {errors.restaurant && (
                  <p className="help is-alert">{errors.restaurant.message}</p>
                )}
                <p className="mt-2 mb-1">
                  ・BOSS E・ZO FUKUOKA
                  3階の飲食店舗ご利用をご検討の場合は、こちらに記載をお願いします。
                </p>
                <div className="field">
                  <label className="label is-hidden" htmlFor="FoodNote">
                    備考(飲食利用)
                  </label>
                  <div className="control" wovn-ignore="true">
                    <textarea
                      className="textarea"
                      id="FoodNote"
                      name="food_note"
                      maxLength="1000"
                      ref={register}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div className="field">
                <label className="label" htmlFor="Name">
                  ご予約名または団体名{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.name ? 'input error' : 'input'}
                    type="text"
                    placeholder="例）福岡ソフトバンクホークス株式会社／e.g.) Fukuoka SoftBank HAWKS Corp."
                    name="name"
                    id="Name"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-user" />
                  </span>
                </div>
                {errors.name && (
                  <p className="help is-alert">{errors.name.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="AgentName">
                  旅行会社名{' '}
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className="input"
                    type="text"
                    placeholder="例）ホークストラベル／e.g.) Hawks Travel"
                    name="agent_name"
                    id="AgentName"
                    ref={register}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-user" />
                  </span>
                </div>
                <p className="help">※旅行代理店の方のみ記入ください</p>
              </div>
              <div className="field">
                <label className="label" htmlFor="BranchName">
                  支店名{' '}
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className="input"
                    type="text"
                    placeholder="例）ホークス支店／e.g.) Hawks Branch"
                    name="branch_name"
                    id="BranchName"
                    ref={register}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-user" />
                  </span>
                </div>
                <p className="help">※旅行代理店の方のみ記入ください</p>
              </div>
              <div className="field">
                <label className="label" htmlFor="InChargePersonName">
                  担当者名{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={
                      errors.in_charge_person_name ? 'input error' : 'input'
                    }
                    type="text"
                    placeholder="例）福岡 太郎／e.g.) Taro Fukuoka"
                    name="in_charge_person_name"
                    id="InChargePersonName"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-user" />
                  </span>
                </div>
                {errors.in_charge_person_name && (
                  <p className="help is-alert">
                    {errors.in_charge_person_name.message}
                  </p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="Telephone">
                  電話番号{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.telephone ? 'input error' : 'input'}
                    type="tel"
                    placeholder="例）0928441189／e.g.) 810928441189"
                    name="telephone"
                    id="Telephone"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-phone-square" />
                  </span>
                </div>
                <p className="help">半角（ハイフン無し）で入力ください。</p>
                {errors.telephone && (
                  <p className="help is-alert">{errors.telephone.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="Fax">
                  FAX番号{' '}
                </label>
                <div
                  className="control has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className="input"
                    type="tel"
                    placeholder="例）0928441189／e.g.) 810928441189"
                    name="fax"
                    id="Fax"
                    ref={register}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-phone-square" />
                  </span>
                </div>
                <p className="help">半角（ハイフン無し）で入力ください。</p>
              </div>
              <div className="field">
                <label className="label" htmlFor="Email">
                  メールアドレス{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.email ? 'input error' : 'input'}
                    type="email"
                    placeholder="例）XXX＠softbankhawks.co.jp／e.g.) XXX＠softbankhawks.co.jp"
                    name="email"
                    id="Email"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope" />
                  </span>
                </div>
                {errors.email && (
                  <p className="help is-alert">{errors.email.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="EmailConfirmation">
                  メールアドレス（確認用）{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={
                      errors.email_confirmation ? 'input error' : 'input'
                    }
                    type="email"
                    placeholder="例）XXX＠softbankhawks.co.jp／e.g.) XXX＠softbankhawks.co.jp"
                    name="email_confirmation"
                    id="EmailConfirmation"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-envelope" />
                  </span>
                </div>
                {errors.email_confirmation && (
                  <p className="help is-alert">
                    {errors.email_confirmation.message}
                  </p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="LeaderName">
                  当日の引率責任者{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control is-expanded has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.leader_name ? 'input error' : 'input'}
                    type="text"
                    placeholder="例）福岡太郎／e.g.) Taro Fukuoka"
                    name="leader_name"
                    id="LeaderName"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-user" />
                  </span>
                </div>
                {errors.leader_name && (
                  <p className="help is-alert">{errors.leader_name.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="LeaderPhone">
                  当日の連絡先{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div
                  className="control has-icons-left has-icons-right"
                  wovn-ignore="true"
                >
                  <input
                    className={errors.leader_phone ? 'input error' : 'input'}
                    type="tel"
                    placeholder="例）0928441189／e.g.) 810928441189"
                    name="leader_phone"
                    id="LeaderPhone"
                    ref={register({
                      required: '必須項目です',
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa fa-phone-square" />
                  </span>
                </div>
                <p className="help">半角（ハイフン無し）で入力ください。</p>
                {errors.leader_phone && (
                  <p className="help is-alert">{errors.leader_phone.message}</p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="Prefecture">
                  都道府県{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div className="control" wovn-ignore="true">
                  <div className="select">
                    <select
                      name="prefecture"
                      id="Prefecture"
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option value="">
                        ---------ご選択ください／Please select---------
                      </option>
                      <option value="北海道">北海道／Hokkaido</option>
                      <option value="青森県">青森県／Aomori</option>
                      <option value="岩手県">岩手県／Iwate</option>
                      <option value="宮城県">宮城県／Miyagi</option>
                      <option value="秋田県">秋田県／Akita</option>
                      <option value="山形県">山形県／Yamagata</option>
                      <option value="福島県">福島県／Fukushima</option>
                      <option value="茨城県">茨城県／Ibaraki</option>
                      <option value="栃木県">栃木県／Tochigi</option>
                      <option value="群馬県">群馬県／Gunma</option>
                      <option value="埼玉県">埼玉県／Saitama</option>
                      <option value="千葉県">千葉県／Chiba</option>
                      <option value="東京都">東京都／Tokyo</option>
                      <option value="神奈川県">神奈川県／Kanagawa</option>
                      <option value="新潟県">新潟県／Niigata</option>
                      <option value="富山県">富山県／Toyama</option>
                      <option value="石川県">石川県／Ishikawa</option>
                      <option value="福井県">福井県／Fukui</option>
                      <option value="山梨県">山梨県／Yamanashi</option>
                      <option value="長野県">長野県／Nagano</option>
                      <option value="岐阜県">岐阜県／Gifu</option>
                      <option value="静岡県">静岡県／Shizuoka</option>
                      <option value="愛知県">愛知県／Aichi</option>
                      <option value="三重県">三重県／Mie</option>
                      <option value="滋賀県">滋賀県／Shiga</option>
                      <option value="京都府">京都府／Kyoto</option>
                      <option value="大阪府">大阪府／Osaka</option>
                      <option value="兵庫県">兵庫県／Hyogo</option>
                      <option value="奈良県">奈良県／Nara</option>
                      <option value="和歌山県">和歌山県／Wakayama</option>
                      <option value="鳥取県">鳥取県／Tottori</option>
                      <option value="島根県">島根県／Shimane</option>
                      <option value="岡山県">岡山県／Okayama</option>
                      <option value="広島県">広島県／Hiroshima</option>
                      <option value="山口県">山口県／Yamaguchi</option>
                      <option value="徳島県">徳島県／Tokushima</option>
                      <option value="香川県">香川県／Kagawa</option>
                      <option value="愛媛県">愛媛県／Ehime</option>
                      <option value="高知県">高知県／Kochi</option>
                      <option value="福岡県">福岡県／Fukuoka</option>
                      <option value="佐賀県">佐賀県／Saga</option>
                      <option value="長崎県">長崎県／Nagasaki</option>
                      <option value="熊本県">熊本県／Kumamoto</option>
                      <option value="大分県">大分県／Oita</option>
                      <option value="宮崎県">宮崎県／Miyazaki</option>
                      <option value="鹿児島県">鹿児島県／Kagoshima</option>
                      <option value="海外／Oversea">海外／Oversea</option>
                    </select>
                  </div>
                </div>
                {errors.prefecture && (
                  <p className="help is-alert">{errors.prefecture.message}</p>
                )}
              </div>
              <hr />
              <div className="field">
                <label className="label" htmlFor="paymentMethod">
                  支払方法{' '}
                  <span className="tag is-rounded is-danger">必須</span>
                </label>
                <div className="control" wovn-ignore="true">
                  <div className="select">
                    <select
                      name="payment_method"
                      id="paymentMethod"
                      ref={register({
                        required: '必須項目です',
                      })}
                    >
                      <option value="">
                        ---------ご選択ください／Please select---------
                      </option>
                      <option value="事前振込">
                        事前振込／Advance payment by bank transfer
                      </option>
                      <option value="クーポン：近畿日本ツーリスト">
                        クーポン：近畿日本ツーリスト
                      </option>
                      <option value="クーポン：日本旅行">
                        クーポン：日本旅行
                      </option>
                      <option value="クーポン：阪急交通社">
                        クーポン：阪急交通社
                      </option>
                      <option value="クーポン：西鉄旅行">
                        クーポン：西鉄旅行
                      </option>
                      <option value="クーポン：東武トップツアーズ">
                        クーポン：東武トップツアーズ
                      </option>
                      <option value="クーポン：農協観光">
                        クーポン：農協観光
                      </option>
                      <option value="クーポン：九州産交ツーリズム">
                        クーポン：九州産交ツーリズム
                      </option>
                      <option value="クーポン：祐徳自動車">
                        クーポン：祐徳自動車
                      </option>
                      <option value="クーポン：サンデン旅行">
                        クーポン：サンデン旅行
                      </option>
                      <option value="クーポン：全旅">クーポン：全旅</option>
                      <option value="クーポン：JTB">クーポン：JTB</option>
                      <option value="クーポン：エイチ・アイ・エス">
                        クーポン：エイチ・アイ・エス
                      </option>
                      <option value="クーポン：T-LIFEパートナーズ">
                        クーポン：T-LIFEパートナーズ
                      </option>
                      <option value="クーポン：名鉄観光サービス">
                        クーポン：名鉄観光サービス
                      </option>
                      <option value="クーポン：ドリームインターフェイス">
                        クーポン：ドリームインターフェイス
                      </option>
                    </select>
                  </div>
                </div>
                {errors.payment_method && (
                  <p className="help is-alert">
                    {errors.payment_method.message}
                  </p>
                )}
              </div>
              <div className="field">
                <label className="label" htmlFor="Note">
                  備考{' '}
                </label>
                <p className="help is-danger">
                  ※障がい者割引の適用ありの場合、対象者数の記載はこちらにお願いします
                </p>
                <div className="control" wovn-ignore="true">
                  <textarea
                    className="textarea"
                    placeholder="2,000文字以内で入力してください（言語によっては入力できる文字数が前後する場合があります）Please enter up to 2,000 characters (the number of characters allowed may vary depending on the language)"
                    id="Note"
                    name="note"
                    maxLength="2000"
                    ref={register}
                  />
                </div>
              </div>
              <p className="notification has-text-align-center mb-4">
                各アトラクション毎の注意事項は事前にご確認ください
              </p>
              <div className="field">
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn-def reverse"
                    onClick={reset}
                  >
                    <span>クリア</span>
                  </button>
                  <button
                    type="submit"
                    className={`btn-def submit ${submit ? 'spin' : ''}`}
                    onClick={validationPost}
                  >
                    <span>進む</span>
                  </button>
                </div>
                <p className="u-pc:ta-c">
                  （メール受信から3営業日以内に返信いたします。）
                </p>
              </div>
            </form>
          </div>
          <div className="btn-wrap mt-5">
            <Button href="/">トップへ戻る</Button>
          </div>
        </div>
      </section>
      <div className={`modal form-modal${isModal ? ' is-active' : ''}`}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className="modal-background" onClick={() => setIsModal(false)} />
        <div className="modal-content">
          <div className="box">
            <p className="is-size-5 has-text-danger has-text-centered has-text-weight-semibold mb-3">
              20名様以上は割引料金が適用されます。
            </p>
            <h4 className="title is-5">王貞治ベースボールミュージアム</h4>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>料金</th>
                    <td>
                      <p className="mb-4">
                        大人（16歳以上）: 1,600円（通常価格1,800円）
                        <br />
                        小人（7歳～15歳）: 900円
                      </p>
                      <p className="list-mark">
                        <span>※</span>
                        6歳以下無料。12歳以下のご利用には要保護者同伴（大人チケットの購入が必要）
                        <br />
                        保護者1名に対しお子様3名までご利用可能
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 className="title is-5">
              王貞治ベースボールミュージアム
              <br className="sp" />
              「89パーク」
            </h4>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>料金</th>
                    <td>
                      <p className="mb-4">
                        大人（16歳以上）: 900円（通常価格1,000円）
                        <br />
                        小人（7歳～15歳）: 500円
                      </p>
                      <p className="list-mark">
                        <span>※</span>
                        6歳以下無料。12歳以下のご利用には要保護者同伴（大人チケットの購入が必要）
                        <br />
                        保護者1名に対しお子様3名までご利用可能
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 className="title is-5">チームラボフォレスト 福岡 – SBI証券</h4>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>料金</th>
                    <td>
                      <p>
                        16歳以上: 2,200円（通常価格2,400円）
                        <br />
                        15歳以下: 900円（通常価格1,000円）
                      </p>
                      <p className="u-mt-10">
                        高校生、大学生、専門学生は、学生料金2000円が適用となります。
                        <br />
                        （アトラクションの入場口で学生証の提示が必要です。）
                      </p>
                      <p className="list-mark u-mt-15">
                        <span>※</span>
                        3歳以下無料。12歳以下のご利用には要保護者同伴（大人チケットの購入が必要）
                        <br />
                        保護者1名に対しお子様3名までご利用可能
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 className="title is-5">V-World AREA クレディ・アグリコル</h4>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>2時間チケット</th>
                    <td>
                      <p className="mb-4">
                        23歳以上: 2,600円（通常価格2,800円）
                        <br />
                        13歳～22歳: 2,000円（通常価格2,200円）
                        <br />
                        12歳以下: 1,500円（通常価格1,700円）
                      </p>
                      <p className="list-mark">
                        <span>※</span>
                        入場は無料です。アクティビティご利用の際はQRコードが確認できるチケットが必要となります。
                        <br />
                        （3歳以下は保護者と一緒に無料で体験していただけます。）
                        <br />
                        但し、6歳以下の方はご利用いただけないアクティビティがございます。
                      </p>
                      <p className="list-mark">
                        <span>※</span>
                        18時以降の15歳以下の入場は保護者同伴が必要となります。
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>1時間チケット</th>
                    <td>
                      <p className="mb-4">
                        23歳以上: 1,800円（通常価格2,000円）
                        <br />
                        13歳～22歳: 1,400円（通常価格1,600円）
                        <br />
                        12歳以下: 900円（通常価格1,000円）
                      </p>
                      <p className="list-mark">
                        <span>※</span>
                        入場は無料です。アクティビティご利用の際はQRコードが確認できるチケットが必要となります。
                        <br />
                        （3歳以下は保護者と一緒に無料で体験していただけます。）
                        <br />
                        但し、6歳以下の方はご利用いただけないアクティビティがございます。
                      </p>
                      <p className="list-mark">
                        <span>※</span>
                        18時以降の15歳以下の入場は保護者同伴が必要となります。
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h4 className="title is-5">すべZO SMBC日興証券</h4>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>料金</th>
                    <td>
                      <p className="mb-4">1,100円（通常価格1,200円）</p>
                      <p className="is-size-7">
                        対象年齢：10歳～64歳
                        <br />
                        身長：130㎝以上
                        <br />
                        体重：35㎏以上
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <article>
              <p className="mb-1">添乗員・ガイド・運転手・カメラマン：無料</p>
              <p className="mb-3">
                料金や注意事項は、各施設の詳細ページでもご確認下さい
              </p>
              <div className="list-base">
                <li>
                  <Link to="/obm" className="tx-link">
                    王貞治ベースボールミュージアム
                  </Link>
                </li>
                <li>
                  <Link to="/teamlabforest" className="tx-link">
                    チームラボフォレスト 福岡 – SBI証券
                  </Link>
                </li>
                <li>
                  <Link to="/v-world" className="tx-link">
                    V-World AREA クレディ・アグリコル
                  </Link>
                </li>
                <li>
                  <Link to="/zekkei-brothers/subezo/" className="tx-link">
                    すべZO SMBC日興証券
                  </Link>
                </li>
              </div>
            </article>
          </div>
        </div>
        <button
          type="button"
          className="modal-close is-large"
          aria-label="close"
          onClick={() => setIsModal(false)}
        />
      </div>
    </Layout>
  );
};

export default useFormPage;
